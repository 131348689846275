import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useFileSelector } from '@stb-utils/hooks';
import { /dev/stb/web_GO_STB } from '@stb-redux/api';
import './styles.scss';
import { Picture } from '@stb-svg';
import swal from 'sweetalert';
import { createSwalButtons } from '@stb/utils/helper';

const ALLOWED_FILE_TYPE = ['image/png', 'image/jpeg', 'image/jpg'];
export default function InputImage({ className, btnTitle, onFileSelected, src, errorRes, title }) {
  const fileInput = useFileSelector();
  const [fileUrl, setFileUrl] = useState(src);
  const [fileBlob, setFileBlob] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (typeof src === 'string' && src.indexOf(/dev/stb/web_GO_STB) !== -1) {
      axios
        .get(src, {
          responseType: 'blob',
          headers: {
            apikey: 'iBLTnMJhpwayXQkxWetXs1vqDjKXX00G',
          },
        })
        .then((response) => {
          // console.log('image res', response);
          const imageData = response.data;
          const imageUrl = URL.createObjectURL(imageData);
          setFileUrl(imageUrl);
        })
        .finally(() => {
          setImageLoading(false);
        });
      setImageLoading(true);
    } else {
      setFileUrl(src);
    }
  }, [src]);

  fileInput.accept = ALLOWED_FILE_TYPE.join(', ');

  fileInput.onchange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    } else if (file && !ALLOWED_FILE_TYPE.includes(file.type)) {
      swal({
        icon: 'error',
        title: 'File tidak sesuai',

        text: 'Silakan unggah kembali dengan tipe .jpg, .png, atau .jpeg',
        buttons: createSwalButtons('Oke'),
      });
      return;
    }
    const filereader = new FileReader();
    filereader.readAsArrayBuffer(file);
    filereader.onload = (readerEvent) => {
      const content = readerEvent.target.result;
      const blobContent = new Blob([content]);
      setFileBlob(blobContent);
      setFileUrl(URL.createObjectURL(blobContent));
      onFileSelected({ fileBlob, file, fileUrl });
    };
  };

  function renderImage() {
    if (imageLoading) {
      return <p className="text-danger">Mohon Tunggu, gambar sedang dimuat</p>;
    }
    return typeof fileUrl === 'string' && fileUrl ? (
      <img src={fileUrl} className="image" />
    ) : (
      <img src={Picture} width={68} />
    );
  }

  return (
    <div className={className}>
      {title !== '' && (
        <label
          style={{
            marginBottom: 12,
          }}
        >
          {title}
        </label>
      )}
      <div
        className={`input-image d-flex align-items-center justify-content-center ${
          errorRes ? 'error' : ''
        }`}
      >
        {renderImage()}
      </div>
      {errorRes && <p className="mt-2 text-danger text-error ">{errorRes}</p>}
      <div className="d-flex mt-4">
        <button
          onClick={() => fileInput.click()}
          className="btn btn-accent btn-submit d-flex align-items-center justify-content-center flex-1"
        >
          {btnTitle}
        </button>
      </div>
    </div>
  );
}

InputImage.defaultProps = {
  className: '',
  btnTitle: 'Unggah Gambar',
  errorRes: '',
  src: '',
  title: '',
  onFileSelected: () => {},
};

InputImage.propTypes = {
  className: PropTypes.string,
  errorRes: PropTypes.string,
  btnTitle: PropTypes.string,
  onFileSelected: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
};
